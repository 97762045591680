import React from 'react';
import Login, { LoginMethod } from '@components/pages/Login/Login';
import ThemeProvider from '@components/theme/ThemeProvider';
import { withAuthenticatedUser } from '@lib/user/auth';
import { GetServerSidePropsContext } from 'next';
import { getApollo } from '@lib/apollo/apolloClient';
import allRooms from '@graphql/Room/queries/all-rooms.graphql';
import { Room } from '@havenly/types';
import { sortBy } from 'lodash';
import { roleBasedRedirect } from '@utils/navigation/serverSideHelpers';

const LoginPage = ({ method }: {method: LoginMethod}) => (
  <ThemeProvider>
    <Login method={method} />
  </ThemeProvider>
);

export default LoginPage;

const domainRegex = new RegExp('^(?:https?:\\/\\/)?(?:[^@\\/\\n]+@)?(?:www\\.)?([^:\\/?\\n]+)');

export const getServerSideProps = withAuthenticatedUser(async ({
  user,
}, ctx: GetServerSidePropsContext) => {
  const { query: { redirect: redirectDest, method } } = ctx;

  if (user) {
    if (redirectDest) {
      if (redirectDest === 'styleQuizLogin') {
        const apolloClient = getApollo({
          jwt: user.jwt,
          cacheEnabled: true,
        });

        const result = await apolloClient.query({
          query: allRooms
        });

        const paidRooms = result.data.allRooms.filter((room: Room) => (room.designFee));
        const unPaidRooms = result.data.allRooms.filter((room: Room) => (!room.designFee));

        if (paidRooms.length > 0) {
          return {
            redirect: {
              permanent: false,
              destination: '/rooms',
            },
          };
        }

        if (unPaidRooms.length > 0) {
          const sortedUnPaidRooms = sortBy(unPaidRooms, [(room: Room) => room.created])
            .reverse();

          const mostRecentUnPaidRoom = sortedUnPaidRooms[0];
          const packageBuilderRedirect = `/rooms/${mostRecentUnPaidRoom.id}/select-package?QuizComplete=true`;

          return {
            redirect: {
              permanent: false,
              destination: packageBuilderRedirect,
            },
          };
        }
      } else if (domainRegex.test(redirectDest.toString()) &&
        redirectDest.toString().toLowerCase().includes('havenly')
      ) {
        return {
          redirect: {
            permanent: false,
            destination: redirectDest,
          },
        };
      } else if (!domainRegex.test(redirectDest.toString()) && redirectDest) {
        return {
          redirect: {
            permanent: false,
            destination: redirectDest,
          },
        };
      }
    }

    if (user.role === 'customer' || user.role === 'designer' || user.role === 'admin') {
      return roleBasedRedirect(user);
    }

    return {
      redirect: {
        permanent: false,
        destination: '/',
      },
    };
  }

  return {
    props: {
      method: method === LoginMethod.Password
        ? LoginMethod.Password
        : LoginMethod.MagicLink,
    }
  };
}, {
  requireAuth: false,
});
