import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import DefaultTemplate from '@components/shared/templates/DefaultTemplate/DefaultTemplate';
import { useTheme } from '@components/theme';
import classNames from 'classnames';
import asyncLogin from '@components/havenly/lib/asyncLogin';
import requestMagicLink from '@components/havenly/lib/requestMagicLink';
import analyticsTrack from '@lib/analytics/analyticsTrack';
import { getResizedImageUrl } from '@lib/image';
import useMergeLocalCartIntoUserCart from '@hooks/User/useMergeLocalCartIntoUserCart';
import Loader from '@components/shared/Loader/Loader';
import TextInput from './shared/TextInput';
import Form from './shared/Form';
import ChangeMethodSignUp from './shared/ChangeMethodSignUp';
import styles from './Login.module.scss';

export enum LoginMethod {
  Password = 'password',
  MagicLink = 'magic-link',
}

export default function Login({ method = LoginMethod.MagicLink }: {method: LoginMethod}) {
  const theme = useTheme();
  const router = useRouter();

  const { redirectUrl } = router.query;

  const [emailSent, setEmailSent] = useState(false);

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [mergeCart] = useMergeLocalCartIntoUserCart();

  const [globalError, setGlobalError] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const submitMagicLinkLogin = async () => {
    await requestMagicLink({
      email,
      redirectUrl: typeof redirectUrl === 'string' ? redirectUrl : undefined
    });
    setEmailSent(true);
  };

  const submitEmailPasswordLogin = async () => {
      await asyncLogin({ email, password });
      await mergeCart();
      analyticsTrack('signin_success', { source: 'login_page' });

      // Using the `window` object directly because we don't want Next.js doing
      // any kind of weird XHR type requests in this scenario.
      window.location.reload();
  };

  const handleOnSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      if (method === LoginMethod.Password) {
        await submitEmailPasswordLogin();
      } else {
        await submitMagicLinkLogin();
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      if (error) {
        if (error.validationErrors) {
          const { validationErrors } = error;
          setEmailError(validationErrors.email || '');
          setPasswordError(validationErrors.password || '');
        }
        if (error.message) {
          setGlobalError(error.message);
        }
      } else {
        setGlobalError('Invalid username and/or password. Please try again.');
      }
    }
  };

  return (
    <DefaultTemplate
      className={theme.styles.Body}
    >
      <div
        className={styles.Root}
        style={{
          backgroundImage: `url('${
            getResizedImageUrl({
              url: 'https://s3.amazonaws.com/static.havenly.com/content/signup-and-login/Havenly_Login_BG.jpg',
              width: 1440,
              quality: 90,
            })
          }')`
        }}
      >
        {isLoading && (
          <>
            <div className={styles.Overlay} />
            <Loader />
          </>
        )}
        {method === LoginMethod.MagicLink ? (
          <MagicLoginForm
            onSubmit={handleOnSubmit}
            globalError={globalError}
            email={email}
            setEmail={setEmail}
            emailError={emailError}
            busy={isLoading}
            emailSent={emailSent}
          />
        ) : (
          <EmailPasswordLoginForm
            onSubmit={handleOnSubmit}
            globalError={globalError}
            email={email}
            setEmail={setEmail}
            emailError={emailError}
            password={password}
            setPassword={setPassword}
            passwordError={passwordError}
            busy={isLoading}
            clearEmailSent={() => setEmailSent(false)}
          />
        )}
      </div>
    </DefaultTemplate>
  );
}

function EmailPasswordLoginForm({
  onSubmit,
  globalError,
  email,
  setEmail,
  emailError,
  password,
  setPassword,
  passwordError,
  busy,
  className = '',
  clearEmailSent
}: {
  onSubmit: (e: React.FormEvent) => void;
  globalError: string;
  email: string;
  setEmail: (email: string) => void;
  emailError: string;
  password: string;
  setPassword: (password: string) => void;
  passwordError: string;
  busy: boolean;
  className?: string;
  clearEmailSent: () => void;
}) {
  const theme = useTheme();

  return (
    <Form
      title={'Welcome Back!'}
      subtitle={'Sign in to your Havenly account'}
      onSubmit={onSubmit}
      globalError={globalError}
      className={className}
    >
      <div className={styles.FieldGroup}>
        <TextInput
          label="Email"
          type="email"
          value={email}
          setValue={setEmail}
          errorMessage={emailError}
          className={styles.TextInputLayout}
        />
        <TextInput
          label="Password"
          type="password"
          value={password}
          setValue={setPassword}
          errorMessage={passwordError}
          className={styles.TextInputLayout}
        />
      </div>
      <div className={styles.CTAButton}>
        <button
          className={classNames(
            theme.styles.Button,
            theme.styles.Primary,
          )}
          type="submit"
          id="submit"
          disabled={busy}
        >
          Sign In
        </button>
      </div>
      <div className={styles.Forgot}>
        <Link
          href="/request-password-reset"
          passHref
        >
          {/* eslint-disable jsx-a11y/anchor-is-valid */}
          <a>
            Forgot your password?
          </a>
        </Link>
      </div>
      <ChangeMethodSignUp
        className={styles.ChangeMethodSignUpLayout}
        method={LoginMethod.Password}
        clearEmailSent={clearEmailSent}
      />
    </Form>
  );
}

function MagicLoginForm({
  onSubmit,
  globalError,
  email,
  setEmail,
  emailError,
  busy,
  emailSent,
  className = ''
}: {
  onSubmit: (e: React.FormEvent) => void;
  globalError: string;
  email: string;
  setEmail: (email: string) => void;
  emailError: string;
  busy: boolean;
  emailSent: boolean;
  className?: string;
}) {
  const theme = useTheme();
  /*
   * Used for autofocus on the username field. (The `autoFocus` attribute wasn't
   * working in this context, for some reason)  The sole purpose of this form
   * is to login, so the accessibility concerns associated with auto-focus are
   * less important here.
   */
  const usernameRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (usernameRef.current) usernameRef.current.focus();
  }, [usernameRef.current]);

  return (
    <Form
      title={emailSent ? 'Check Your Inbox!' : 'Sign In With Email'}
      subtitle={emailSent ? `To login, tap the button in the email we sent to ${email}` : "We'll email you a link for a password-free sign in."}
      onSubmit={onSubmit}
      globalError={globalError}
      className={className}
    >
      {!emailSent && (
        <>
          <div className={styles.FieldGroup}>
            <TextInput
              label="Email"
              type="email"
              value={email}
              setValue={setEmail}
              errorMessage={emailError}
              ref={usernameRef}
              className={styles.TextInputLayout}
            />
          </div>
          <div className={styles.CTAButton}>
            <button
              className={classNames(
                theme.styles.Button,
                theme.styles.Primary,
              )}
              type="submit"
              id="submit"
              disabled={busy}
            >
              Sign In with Email
            </button>
          </div>
        </>
      )}
      <ChangeMethodSignUp
        className={styles.ChangeMethodSignUpLayout}
        method={LoginMethod.MagicLink}
      />
    </Form>
  );
}
