/* istanbul ignore file */
import fetch from 'isomorphic-unfetch';
import logger from '@lib/logger';
import { safeParseJson } from '@lib/json';

interface IMagicLoginArgs {
  email: string;
  redirectUrl?: string;
}

export default async function requestMagicLink({ email, redirectUrl }:IMagicLoginArgs) {
  try {
    const body: IMagicLoginArgs = {
      email
    };

    if (redirectUrl) {
      body.redirectUrl = redirectUrl;
    }
    return await fetch('/request-magic-link', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json'
      },
      body: JSON.stringify(body),
    }).then(async (response) => {
      const json = safeParseJson(await response.text());
      json.status = response.status;
      if (!response.ok) {
        throw json;
      }
      return json;
    });
  } catch (error: any) {
    logger('requestMagicLink').error(error);

    throw error;
  }
}
