import React from 'react';
import { useTheme } from '@components/theme';

import Danger from './danger.svg';
import styles from './Form.module.scss';

export default function Form({
  title,
  subtitle,
  onSubmit,
  globalError = '',
  children,
  className = '',
}: React.PropsWithChildren<{
  title: string;
  subtitle?: string;
  onSubmit?: (e: React.FormEvent) => void;
  globalError?: string;
  className?: string;
}>) {
  const theme = useTheme();
  return (
    <form
      className={`${styles.Form} ${className}`}
      name={`login-form`}
      onSubmit={onSubmit}
    >
      <h2 className={`${theme.styles.Title2} ${styles.Title}`}>
        {title}
      </h2>
      {subtitle && (<p className={styles.Subtitle}>{subtitle}</p>)}
      <div className={`${styles.GlobalError} ${globalError ? styles.Visible : ''}`}>
        <span><Danger />{globalError}</span>
      </div>
      {children}
    </form>
  );
}
