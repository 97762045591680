import React from 'react';

import styles from './Or.module.scss';

export default function Or({
  className = ''
}: {
  className?: string;
}) {
  return (
    <div className={`${styles.Or} ${className}`}>
      <div className={styles.Line} />
      <div className={styles.OrText}>OR</div>
      <div className={styles.Line} />
    </div>
  );
}
