import React from 'react';
import Link from 'next/link';

import { LoginMethod } from '../Login';
import Or from './Or';
import styles from './ChangeMethodSignUp.module.scss';

export default function ChangeMethodSignUp({
  className = '',
  method,
  clearEmailSent
}: {
  className?: string;
  method: LoginMethod;
  clearEmailSent?: () => void;
}) {
  return (
    <div
      className={`${styles.ChangeMethodSignUp} ${className}`}
    >
      <Or
        className={styles.OrLayout}
      />
      <Link
        href={`/login?method=${method === LoginMethod.Password ? LoginMethod.MagicLink : LoginMethod.Password}`}
        passHref
      >
        {/* eslint-disable */}
        <a
          className={styles.Forgot}
          onClick={clearEmailSent}
        >
          {method === LoginMethod.Password ? 'Sign in with just your email' : 'Sign in with a password'}
        </a>
      </Link>
      <p className={styles.SignUp}>Don&apos;t have an account? <Link href="/users/add">Sign up.</Link></p>
    </div>
  );
}
